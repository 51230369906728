import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {},

  hover: {
    '&:hover': {
      backgroundColor: `${theme.palette.list.chosen} !important`,
    },
  },

  selected: {
    backgroundColor: `${theme.palette.list.chosen} !important`,
  },

  interactive: {
    cursor: 'pointer',
  },

  expanded: {
    '& td': {
      borderBottom: 'none',
    },
  },
}));
