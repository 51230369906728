import React from 'react';
import cn from 'classnames';
import MUITableRow, { TableRowProps } from '@material-ui/core/TableRow';

import useStyles from './useStyles';

interface Props extends TableRowProps {
  interactive?: boolean;
  expanded?: boolean;
}

const TableRow = ({ className, children, interactive = false, expanded = false, ...rest }: Props) => {
  const classes = useStyles();
  const classNames = cn(className, classes.root, {
    [classes.interactive]: interactive,
    [classes.expanded]: expanded,
  });

  const overrideClasses = {
    root: classes.root,
    hover: classes.hover,
    selected: classes.selected,
  };

  return (
    <MUITableRow className={classNames} classes={overrideClasses} {...rest}>
      {children}
    </MUITableRow>
  );
};

export default TableRow;
